import React from "react"
import { graphql } from "gatsby"
import { GeneralPageLayout } from "../components/GeneralPageLayout"
import ContentBox from "../components/ContentBox"
import PWithBr from "../components/PWithBr"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import {
  imageStyles,
  desktopMediaQuery,
  pStyle,
  sizes,
  colors,
} from "../styles/constants"
function AroundPageTemplate({ title, sites }) {
  return (
    <GeneralPageLayout title={title}>
      <div css={styles.container}>
        {sites.map(s => {
          return (
            <div css={styles.boxContainer}>
              <ContentBox>
                <h4
                  className="title"
                  css={css`
                    color: ${colors.primary800};
                  `}
                >
                  {s.name}
                </h4>
                <div css={styles.images.container}>
                  {s.image !== null && (
                    <div css={styles.images.imgWrapper}>
                      <Img fluid={s.image.childImageSharp.fluid} />
                    </div>
                  )}
                </div>
                <PWithBr css={pStyle.longP}>{s.text}</PWithBr>
              </ContentBox>
            </div>
          )
        })}
      </div>
    </GeneralPageLayout>
  )
}

function AroundPage({ data }) {
  return <AroundPageTemplate {...data.markdownRemark.frontmatter} />
}

export default AroundPage

const styles = {
  container: css`
    max-width: 900px;
    margin: auto;
  `,
  images: {
    container: css`
      display: flex;
      flex-wrap: wrap;
    `,
    imgWrapper: css`
      min-height: 5rem;
      width: 100%;
      padding: 0.5rem 0;
      ${desktopMediaQuery} {
        margin: auto;
        min-height: 10rem;
        min-width: 3rem;
        width: 50%;
        padding: 1rem 1rem 1rem 0;
      }
      & img {
        ${imageStyles.borderRadius};
      }
    `,
  },
  boxContainer: css`
    padding-bottom: ${sizes.smallScale.s};
  `,
}

export const aroundPageQuery = graphql`
  query AroundPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        sites {
          name
          text
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
