import { css } from "@emotion/core"
import React from "react"
import { backgroundPatterns, pageTitle } from "../../styles/constants"
import { Section } from "../Styled"
import { Container } from "../styledComponents"

function GeneralPageLayout({ title, children }) {
  return (
    <div css={[styles.root, backgroundPatterns.grayish]}>
      <h1 css={[pageTitle, styles.title]}>{title}</h1>
      <Section>
        <Container>{children}</Container>
      </Section>
    </div>
  )
}

export default GeneralPageLayout

const styles = {
  root: css`
    width: 100%;
  `,
  title: css`
    font-size: 3rem;
    text-align: center;
    font-weight: 600;
    line-height: 1.125;
  `,
}
